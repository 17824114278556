<template>
  <div
    class="h-3 w-3 rounded-full"
    :class="{
      'bg-skin-danger': !socketConnexionStatus,
      'bg-skin-valid': socketConnexionStatus,
    }"
  ></div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAppStore } from '~/stores/app';

const { socketConnexionStatus } = storeToRefs(useAppStore());
</script>
